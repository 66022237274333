<template>
  <div class="index page-wrapper">
    <a-row type="flex" justify="space-around">
      <a-col :span="5">
        <a-card class="data-index" @click="handleCardClick('/account')">
          <a-statistic title="用户" :value="accountNumber">
            <template #prefix>
              <a-icon type="user" />
            </template>
          </a-statistic>
        </a-card>
      </a-col>
      <a-col :span="5">
        <a-card class="data-index" @click="handleCardClick('/mamber')">
          <a-statistic title="会员" :value="memberNumber">
            <template #prefix>
              <a-icon type="crown" />
            </template>
          </a-statistic>
        </a-card>
      </a-col>
      <a-col :span="5">
        <a-card class="data-index" @click="handleCardClick('/exhibitor')">
          <a-statistic title="展商" :value="exhibitorNumber">
            <template #prefix>
              <a-icon type="team" />
            </template>
          </a-statistic>
        </a-card>
      </a-col>
      <a-col :span="5"
        ><a-card class="data-index" @click="handleCardClick('/exhibit')">
          <a-statistic title="展品" :value="exhibitNumber">
            <template #prefix>
              <a-icon type="star" />
            </template>
          </a-statistic>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import AccountService from "../services/account";
import ExhibitService from "../services/exhibit";
import MamberService from "../services/mamber";
import ExhibitorService from "../services/exhibitor";
// const colors = ["pink", "red", "orange", "green", "cyan", "blue", "purple"];

export default {
  data() {
    return {
      accountNumber: 0,
      memberNumber: 0,
      exhibitorNumber: 0,
      exhibitNumber: 0,
    };
  },
  methods: {
    handleCardClick(url) {
      this.$router.push(url);
    },
    async countAccount() {
      const number = await AccountService.countEntity({
        type: "WECHAT_APPLET",
      });
      this.accountNumber = number;
    },
    async countMamber() {
      const number = await MamberService.countEntity();
      this.memberNumber = number;
    },
    async countExhibit() {
      const number = await ExhibitService.countEntity();
      this.exhibitNumber = number;
    },
    async countExhibitor() {
      const number = await ExhibitorService.countEntity();
      this.exhibitorNumber = number;
    },
  },
  mounted() {
    this.countAccount();
    this.countMamber();
    this.countExhibit();
    this.countExhibitor();
  },
};
</script>

<style lang="less" scoped>
.index {
  .data-index {
    cursor: pointer;
  }
}
</style>